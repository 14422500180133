<template>
  <auth-layout :image="signup" v-if="!currentUser">
    <cz-prompt-dialog
      v-if="promptDialog.show"
      v-model="promptDialog.show"
      v-bind="promptDialog"
      @done="promptDialog.show = false"
    />
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-card
        :max-width="500"
        :width="500"
        :loading="loading"
        class="pa-6"
        flat
        color="background"
      >
        <v-card-title class="texPrimary--text">{{
          $t('customer.signupTitle')
        }}</v-card-title>
        <v-card-subtitle class="font-weight-semibold">{{
          subtitle
        }}</v-card-subtitle>
        <v-form @submit.prevent="onSignUp" v-if="invitation">
          <v-card-text>
            <cz-form-field :label="$t('auth.email')" required>
              <validation-provider
                rules="required|email"
                :name="$t('auth.email')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.email"
                  :error-messages="errors"
                  readonly
                  outlined
                  :prepend-inner-icon="mdiEmail"
                />
              </validation-provider>
            </cz-form-field>
            <cz-form-field
              v-if="invitation.isAccountSpecific"
              :label="$t('auth.companyName')"
              required
            >
              <validation-provider
                rules="required"
                :name="$t('auth.companyName')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.companyName"
                  :error-messages="errors"
                  readonly
                  :prepend-inner-icon="mdiBriefcase"
                />
              </validation-provider>
            </cz-form-field>
            <cz-form-field :label="$t('auth.fullName')" required>
              <validation-provider
                rules="required|fullName"
                :name="$t('auth.fullName')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.fullName"
                  :error-messages="errors"
                  :prepend-inner-icon="mdiAccountCircle"
                />
              </validation-provider>
            </cz-form-field>

            <cz-form-field :label="$t('auth.choosePassword')" required>
              <validation-provider
                rules="required|password"
                :name="$t('auth.password')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.password"
                  :error-messages="errors"
                  type="password"
                  :prepend-inner-icon="mdiLock"
                  :placeholder="$t('auth.choosePasswordToUse')"
                />
              </validation-provider>
            </cz-form-field>
            <v-fade-transition>
              <v-alert type="error" v-if="errorMessage">{{
                errorMessage
              }}</v-alert>
            </v-fade-transition>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              x-large
              type="submit"
              :loading="loading"
              class="full-width font-weight-semibold"
              :disabled="invalid"
              >{{ $t('auth.signMeUp') }}</v-btn
            >
          </v-card-actions>
          <div class="d-flex justify-center mt-2">
            <cz-button text color="primary" @click="onLogin">{{
              $t('auth.alreadyHaveAccountLogin')
            }}</cz-button>
          </div>
        </v-form>
      </v-card>
    </validation-observer>
  </auth-layout>
  <content-layout v-else class="d-flex justify-center align-center">
    <v-card :width="500" :loading="loading">
      <v-card-title class="text-h5 primary--text font-weight-semibold">{{
        $t('invite.invitationTitle', [this.invitation?.assignedRolesNames[0]])
      }}</v-card-title>
      <v-card-text class="text-body-1 textPrimary--text text-pre-wrap">{{
        $t('invite.invitationMessage', [
          this.invitation?.user?.fullName,
          this.invitation?.assignedRolesNames[0]
        ])
      }}</v-card-text>
      <v-card-actions class="justify-space-between">
        <cz-button
          color="primary"
          :title="'אישור וקבלת ההזמנה'"
          @click="onAccept"
          :loading="loading"
        />
        <cz-button
          small
          color="error"
          text
          title="דחיית הזמנה"
          @click="onReject"
          :disabled="loading"
        />
      </v-card-actions>
    </v-card>
  </content-layout>
</template>

<script>
import signup from '@/assets/illustrations/signup.svg';
import {
  mdiAccountCircle,
  mdiEmail,
  mdiLock,
  mdiEye,
  mdiEyeOff,
  mdiBriefcase
} from '@mdi/js';
import { CzFormField, CzInput, CzButton, CzPromptDialog } from '@/components';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { acceptInvite } from '@/core/api';
export default {
  name: 'NewCustomerInvitePage',
  components: {
    AuthLayout: () => import('@/layouts/AuthLayout.vue'),
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzFormField,
    CzInput,
    CzButton,
    CzPromptDialog
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  created() {
    if (this.checkInvite()) {
      this.form.companyName = this.invitation.account?.companyName;
      this.form.email = this.invitation.inviteeEmail;
    }
  },
  computed: {
    ...mapFields('invite', ['invitation']),
    ...mapFields('auth', ['currentUser']),
    subtitle() {
      if (!this.invitation) {
        return '';
      }

      if (this.invitation.isAccountSpecific) {
        return this.$t('customer.signupSubtitleAccountSpecific', [
          this.invitation.account?.companyName || '',
          this.invitation.assignedRolesNames[0]
        ]);
      } else {
        return this.$t('customer.signupSubtitleNotAccountSpecific', [
          this.invitation.assignedRolesNames[0]
        ]);
      }
    }
  },
  data() {
    return {
      signup,
      mdiAccountCircle,
      mdiEmail,
      mdiLock,
      mdiEye,
      mdiEyeOff,
      mdiBriefcase,
      loading: false,
      errorMessage: '',
      form: {
        fullName: '',
        email: '',
        password: '',
        companyName: ''
      },
      promptDialog: {
        show: false,
        message: '',
        hideCancelButton: true
      }
    };
  },
  methods: {
    ...mapActions('auth', ['signUp']),
    checkInvite() {
      if (!this.id || !this.invitation || this.id !== this.invitation._id) {
        this.$router.replace({
          name: 'home'
        });
        return false;
      }

      return true;
    },
    async onSignUp() {
      try {
        this.loading = true;
        await this.signUp({
          ...this.form,
          inviteId: this.invitation._id,
          inviteToken: this.invitation.longToken
        });
        this.$router.replace({
          name: 'home'
        });
      } catch (error) {
        this.promptDialog.message = error.message;
        this.promptDialog.show = true;
      } finally {
        this.loading = false;
      }
    },
    onLogin() {
      this.$router.replace({
        name: 'login',
        query: {
          redirect: `/invite?to=${this.invitation.inviteeEmail}&token=${this.invitation.longToken}`
        }
      });
    },
    async onAccept() {
      try {
        this.loading = true;
        await acceptInvite(
          this.invitation.inviteeEmail,
          this.invitation.longToken,
          'accepted'
        );
        this.$router.replace({
          name: 'home'
        });
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async onReject() {
      try {
        await acceptInvite(
          this.invitation.inviteeEmail,
          this.invitation.longToken,
          'rejected'
        );
        this.$router.replace({
          name: 'home'
        });
      } finally {
      }
    }
  }
};
</script>

<style></style>
